<template>
  <div class="videoPlay">
    <video-player
      ref="videoPlayer"
      class="video-player vjs-custom-skin"
      :playsinline="true"
      :options="playerOptions"
      @timeupdate="onPlayerTimeupdate($event)"
      @ended="onPlayerEnded($event)"
      @play="onPlayerPlay($event)"
      @ready="playerReadied"
    />
  </div>
</template>

<script>
import { recordUpdate } from '../api/study/index'

export default {
  name: 'VideoPlay',
  props: {
    videoUrl: String,
    videoId: String,
    relatedId: String,
    watchRecordTime: [Number, String],
    state: Boolean
  },
  data() {
    return {
      playerOptions: {
        // 播放速度
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        // 如果true,浏览器准备好时开始回放。
        autoplay: false,
        // 默认情况下将会消除任何音频。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: 'auto',
        language: 'zh-CN',
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: '16:9',
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [{
          // 类型
          type: 'video/mp4',
          // url地址
          src: this.videoUrl
        }],
        // 你的封面地址
        poster: '',
        // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          // 全屏按钮
          fullscreenToggle: true
        }
      },
      // 当前视频播放的时间，s
      currentTime: '',
      // 当前视频id
      currentVideoId: '',
      // 当前视频关联表id
      currentRelateId: ''
    }
  },
  watch: {
    videoUrl: function(val) {
      if (val !== '') {
        this.playerOptions.sources[0].src = val
      } else {
        this.playerOptions.sources[0].src = val
      }
    },
    videoId: {
      handler(val) {
        this.currentVideoId = val
      },
      deep: true,
      immediate: true
    },
    relatedId: {
      handler(val) {
        console.log(val)
        this.currentRelateId = val
      },
      deep: true,
      immediate: true
    },
    watchRecordTime: {
      handler(val) {
        console.log(val)
        this.currentTime = val
      },
      deep: true,
      immediate: true
    }
    // state : function ( val) {
    //   if ( val) {
    //     this. $refs. videoPlayer. player. pause()
    //   }
    // }
  },
  methods: {
    onPlayerTimeupdate(event) {
      console.log(event)
      this.$emit('updateTime', event.cache_.currentTime, 1)
    },
    // 视频播完回调
    onPlayerEnded(player) {
      console.log(player)
      this.$emit('updateTime', player.cache_.currentTime, 2)
      // 记忆播放状态0未开始，1进行中，2已结束
      this.videoRecord(player.cache_.currentTime, 2)
    },
    // 播放回调
    onPlayerPlay(player) {
      console.log('player play!', player)
    },
    playerReadied(player) {
      console.log('example player 1 readied', player)
      if (this.currentTime !== '') {
        player.currentTime(Number(this.currentTime))
      }
    },
    // 调用保存记录的接口
    videoRecord(record, watrchstatus) {
      const obj = {
        id: this.currentVideoId,
        classOutlineVideoId: this.currentRelateId,
        watchStatus: watrchstatus,
        watchRecord: record
      }
      recordUpdate(obj).then(res => {
        console.log(res)
        if (res.code === 200) {
          console.log(res)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
    .videoPlay {
        display: inline-block;
        width: 600px;
        height: 338px;
        text-align: center;
        line-height: 100px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
        margin-right: 4px;
    }

    .videoPlay:hover {
        display: block;
    }

</style>
