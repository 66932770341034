<template>
  <div class="videoPage">
    <div v-loading="loading" class="box" element-loading-background="rgba(0, 0, 0, 0)" :class="{'getCenter':videoData.length==0}">
      <div :class="total!=0?'list-box':'middle-box'">
        <div v-for="i in videoData" :key="i.id" class="item" @click="getDetail(i)">
          <div class="play" style="position: relative">
            <video
              id="upVideo"
              width="220"
              height="146"
              :src="$downloadUrl+i.fileUrls"
            />
          </div>
          <el-tooltip class="tooltip" effect="dark" :content="i.name" placement="top" :disabled="!showTip">
            <div class="intro" @mouseover="textRange">
              {{ i.name }}
            </div>
          </el-tooltip>

        </div>
        <el-empty v-if="total==0" description="暂无数据" :image="require('@/assets/images/empty.png')" />
      </div>

    </div>
    <div v-if="total>0" class="pageBox">
      <div style="text-align: right;" class="page">
        <el-pagination
          background
          :current-page="currentPage"
          :page-size="pageSize"
          layout=" prev, pager, next, jumper"
          :total="total"
          @current-change="currentChange"
        />
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="635px"
      :before-close="handleClose"
    >
      <video-play :video-url="url" :video-id="videoId" :related-id="relatedId" :watch-record-time="watchRecordTime" @updateTime="updateVideoCurrentTime" />
    </el-dialog>
  </div>
</template>

<script>
import videoPlay from '../../components/videoPlay'
import { recordUpdate, videoList } from '../../api/study'
import { mapState } from 'vuex'
export default {
  name: 'VideoPage',
  components: { videoPlay },
  created() {
    // this.onload()
    console.log(this.$store.state.currentNode)
    const obj = {
      studentId: this.$store.state.user.userInfo.id,
      pageSize: this.pageSize,
      pageNum: this.currentPage,
      classId: this.$store.state.user.userInfo.classId,
      classOutlineId: this.current,
      sonId: this.$store.state.currentNode.sonId
    }
    console.log(obj)
    // 刚进来不再进行查询，因为此时没有classOutlineId
    if (!this.current) {
      console.log(obj)
    } else {
      videoList(obj).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.loading = false
          this.videoData = res.rows
          this.total = res.total
        }
      })
    }
  },
  computed: {
    ...mapState({
      current: state => state.currentNode.id
    })
  },
  watch: {
    current() {
      this.onLoad()
    }
  },
  data() {
    return {
      url: '',
      videoId: '',
      relatedId: '',
      watchRecordTime: '',
      currentVideoTime: '',
      currentVideoStatus: 1,
      loading: false,
      dialogVisible: false,
      videoData: [],
      currentPage: 1,
      pageSize: 9,
      total: 0,
      showTip: false
    }
  },
  methods: {
    onLoad() {
      const obj = {
        studentId: this.$store.state.user.userInfo.id,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        classId: this.$store.state.user.userInfo.classId,
        classOutlineId: this.current,
        sonId: this.$store.state.currentNode.sonId
      }
      videoList(obj).then(res => {
        if (res.code === 200) {
          this.loading = false
          this.videoData = res.rows
          if (this.videoData.length !== 0) {
            this.videoData.map(i => {
              i.fileUrls = encodeURI(i.fileUrls).replace('+', '%2B')
            })
          }
          console.log('aaa', this.videoData)
          this.total = res.total
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
      // 保存播放记录
      this.videoRecord()
      this.onLoad()
      this.url = ''
      this.videoId = ''
      this.relatedId = ''
      this.watchRecordTime = ''
      this.currentVideoTime = ''
    },

    getDetail(data) {
      console.log(data)
      this.dialogVisible = true
      // this.url =this.$downloadUrl+ data.fileUrls
      this.url = this.$downloadUrl + data.fileUrls
      this.videoId = data.recordId
      this.relatedId = data.classOutlineVideoId
      this.watchRecordTime = data.watchRecord
      // 用来保存时间的变量也需要更新，该变量会在视频播放时实时更新，this.updateVideoCurrentTime
      this.currentVideoTime = data.watchRecord
    },
    // 调用保存记录的接口
    videoRecord() {
      const obj = {
        id: this.videoId,
        classOutlineVideoId: this.relatedId,
        watchStatus: this.currentVideoStatus,
        watchRecord: this.currentVideoTime
      }
      recordUpdate(obj).then(res => {
        console.log(res)
        if (res.code === 200) {
          console.log(res)
        }
      })
    },
    // 修改当前视频额播放时间
    updateVideoCurrentTime(val, status) {
      this.currentVideoTime = val.toString()
      this.currentVideoStatus = status
    },
    currentChange(val) {
      console.log(val)
      this.currentPage = val
      this.onLoad()
    },
    textRange(el) {
      console.log(el)
      const textContent = el.target
      const targetW = textContent.getBoundingClientRect().width
      const range = document.createRange()
      range.setStart(textContent, 0)
      range.setEnd(textContent, textContent.childNodes.length)
      const rangeWidth = range.getBoundingClientRect().width
      console.log(rangeWidth > targetW)
      this.showTip = rangeWidth > targetW
    }
  }
}
</script>

<style scoped lang="less">
    .getCenter{
        justify-content: center !important;
    }
    /deep/ .el-dialog__header{
        padding: 0 0 10px 0;
    }

    .videoPage{
        /deep/ .el-dialog{
            min-height: 10px;
        }
        .box{
            width: 770px;
            //justify-content: left;
            min-height: 680px;
            /*background: #ffffff;*/
            margin: 10px;
            //display: flex;
            flex-wrap:wrap;

            >.list-box{
              display: flex;
              flex-wrap: wrap;
              width: 770px;
                margin-left: 20px;
                margin-top: 20px ;
            }
            >.middle-box {
              padding-top: 200px;
            }
            .item{
                width: 230px;
                //background: white;
                border-radius: 20px;
                margin:  10px;

                .intro{
                    text-align: left;
                    padding: 10px 10px 0 10px;
                    background: white;
                    min-height: 50px;
                    border: 1px solid white;
                    border-radius: 6px;
                    font-size: 14px;
                    line-height: 20px;
                    color: rgba(48, 48, 48, 1);
                    overflow : hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .play{
                    width: 230px;
                    /*height: 148px;*/
                    border: 1px solid #999;
                    border-radius: 6px;
                    background: black;

                }
                .title{
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    margin-top: 8px;
                    text-align: left;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }
            }
            .pageBox{
                width: 770px;
                margin-top: 40px;
                position: relative;
                .page{
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                    border: 1px solid #E8E8E8;
                    width: auto;
                }
                //::v-deep.el-pagination{
                //    .el-pager{
                //        .number{
                //            color: #999999;
                //        }
                //        .active{
                //            color: #39A5EF;
                //        }
                //        .more{
                //            color: #999999;
                //        }
                //    }
                //}
            }
        }
    }

</style>
