import request from "@/utils/request";

//树
export function classTreeList(data) {
  return request({
    url: `api/tmClassOutline/listTree`,
    method: 'post',
    data
  })
}
export function changeStudyStutus(data) {
  return request({
    url: `api/tmStudentOutline/editStatus`,
    method: 'post',
    data
  })
}
//资料列表
export function datasList(data) {
  return request({
    url: `api/tmWantStudy/datasList?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}
//练习题列表
export function ExeList(data) {
  return request({
    url: `api/tmWantStudy/exercisesList?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}
//视频列表
export function videoList(data) {
  return request({
    url: `api/tmWantStudy/videoList?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}
//视频记忆
export function recordUpdate(data) {
  return request({
    url: `api/tmClassVideoRecord/update`,
    method: 'post',
    data
  })
}
